import { FC } from 'react'
import useTranslations from '../I18nProvider/I18nProvider'
import classNames from 'classnames'

import styles from './Bulletpoint.module.scss'

type BulletpointProps = {
  title: string
  content: string[]
  isOpen: boolean
  openHandler: (title: string) => void
}

const Bulletpoint: FC<BulletpointProps> = ({ title, content, isOpen, openHandler }) => {
  const t = useTranslations('index.integrationsSection')
  const titleClass = isOpen ? styles.title : classNames(styles.title, styles.passiveTitle)

  const onOpen = () => {
    openHandler(title)
  }

  return (
    <div className={styles.component}>
      <p onClick={onOpen} className={titleClass}>
        {title}
      </p>
      <div className={`${styles.base} ${isOpen ? styles.isOpen : styles.isClosed}`}>
        {content.map((sentence) => {
          return (
            <p
              key={sentence}
              className={`${styles.content} ${styles.base} ${
                isOpen ? styles.isOpen : styles.isClosed
              }`}
            >
              {sentence}
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default Bulletpoint
