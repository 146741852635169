import { FC } from 'react'

interface Props {
  className?: string
}

const TransportIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g opacity="0.7">
        <path
          d="M3.8 6L3.8 15.5652H2L2 23.3913H4.83008C5.23496 24.8833 6.6347 26 8.3 26C9.95923 26 11.3065 24.8751 11.7172 23.3913L19.2301 23.3913C19.635 24.8833 21.0347 26 22.7 26C24.3653 26 25.765 24.8833 26.1699 23.3913H29L29 14.3356L23.0727 8.6087L18.2 8.6087V15.5652H16.4V6L3.8 6ZM5.6 7.73913L14.6 7.73913L14.6 15.5652L5.6 15.5652L5.6 7.73913ZM8.3 9.47826V11.2174H11.9V9.47826L8.3 9.47826ZM20 10.3478L22.3273 10.3478L27.2 15.0557L27.2 21.6522H26.1699C25.765 20.1602 24.3653 19.0435 22.7 19.0435C21.6226 19.0435 20.6616 19.5184 20 20.251V10.3478ZM3.8 17.3043H18.2L18.2 21.6522H11.7172C11.3065 20.1684 9.95923 19.0435 8.3 19.0435C6.6347 19.0435 5.23496 20.1602 4.83008 21.6522H3.8L3.8 17.3043ZM8.3 20.7826C9.305 20.7826 10.1 21.5507 10.1 22.5217C10.1 23.4928 9.305 24.2609 8.3 24.2609C7.295 24.2609 6.5 23.4928 6.5 22.5217C6.5 21.5507 7.295 20.7826 8.3 20.7826ZM22.7 20.7826C23.705 20.7826 24.5 21.5507 24.5 22.5217C24.5 23.4928 23.705 24.2609 22.7 24.2609C21.695 24.2609 20.9 23.4928 20.9 22.5217C20.9 21.5507 21.695 20.7826 22.7 20.7826Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default TransportIcon
