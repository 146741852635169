import { FC } from 'react'

interface Props {
  className?: string
}

const PackingIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g opacity="0.7">
        <path
          d="M15.3887 4V10.5859L12.8027 8L11.3887 9.41406L16.3887 14.4141L21.4219 9.41406L20.0078 8L17.3887 10.5957V4H15.3887ZM9.72656 10.5801L5.82812 12.043L1 17.002L5.38867 18.9648L5.38867 23.5312L16.3887 28.7578L27.3887 23.5313V18.9648L31.7773 17.002L26.9492 12.043L23.0508 10.5801L21.498 12.1328L23.7324 12.9707L16.3887 16.1836L9.04492 12.9707L11.2832 12.1328L9.72656 10.5801ZM6.62305 14.0938L14.4531 17.5215L12.1699 19.8047L4.43359 16.3438L6.62305 14.0938ZM26.1562 14.0938L28.3496 16.3438L20.6113 19.8047L18.3262 17.5195L26.1562 14.0938ZM15.3887 19.4121L15.3887 26.0684L7.38867 22.2676L7.38867 19.8594L12.6094 22.1934L15.3887 19.4121ZM17.3887 19.4121L20.168 22.1934L25.3887 19.8594V22.2676L17.3887 26.0684V19.4121Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default PackingIcon
