import classNames from 'classnames'
import useTranslations from '../I18nProvider/I18nProvider'
import { CSSProperties, FC } from 'react'
import ImageBlocks from './ImageBlocks'
import styles from './IndexServicesSection.module.scss'
import TextInfoBlock from './TextInfoBlock'

type Props = {
  className?: string
  style?: CSSProperties
}

const IndexServicesSection: FC<Props> = ({ className, style }) => {
  const t = useTranslations('index.introSection')

  return (
    <>
      <div id="services" className="anchor"></div>
      <div className={classNames(styles.component, className)} style={style}>
        <TextInfoBlock />
        <ImageBlocks />
      </div>
    </>
  )
}

export default IndexServicesSection
