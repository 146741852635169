import { CSSProperties, FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import classNames from 'classnames'

import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import ThreeByThreeDots from '../ThreeByThreeDots/ThreeByThreeDots'
import IntegrationBulletpoints from './IntegrationBulletpoints'
import styles from './IntegrationInfo.module.scss'

type IntegrationInfoProps = {
  className?: string
  style?: CSSProperties
}

const IntegrationInfo: FC<IntegrationInfoProps> = ({ className, style }) => {
  const t = useTranslations('index.integrationsSection')

  return (
    <div className={classNames(styles.component, className)} style={style}>
      <div className={styles.dotsContainer}>
        <ThreeByThreeDots className={styles.dots} />
      </div>
      <div className={styles.label}>{t('integration-label')}</div>
      <h1 className={styles.title}>{t('integration-title')}</h1>
      <div className={styles.integrationBulletpoints}>
        <IntegrationBulletpoints />
      </div>
      <div className={styles.furtherInfoButtonContainer}>
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton label={t('further-info-button-label')} />
          </a>
        </Link>
      </div>
    </div>
  )
}

export default IntegrationInfo
