import { FC } from 'react'
import Link from 'next/link'
import SzechenyiLogo from '../SzechenyiLogo/SzechenyiLogo'

const SzechenyiLogoWithLink: FC = () => {
  return (
    <Link href="/szechenyi2020">
      <a>
        <SzechenyiLogo />
      </a>
    </Link>
  )
}

export default SzechenyiLogoWithLink
