import { FC } from 'react'
import Link from 'next/link'
import styles from './Fab.module.scss'
import classNames from 'classnames'
import { CSSProperties } from 'react-google-recaptcha/node_modules/@types/react'
import ArrowUpIcon from '../icons/ArrowUpIcon'

type Props = {
  className?: string
  style?: CSSProperties
}

const Fab: FC<Props> = ({ className, style }) => {
  return (
    <Link href="#top">
      <a>
        <div className={classNames(className, styles.component)} style={style}>
          <div>
            <ArrowUpIcon />
          </div>
        </div>
      </a>
    </Link>
  )
}

export default Fab
