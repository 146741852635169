import { CSSProperties, FC } from 'react'
import classNames from 'classnames'

import styles from './IndexIntegrationsSection.module.scss'
import Webshops from './Webshops'
import IntegrationInfo from './IntegrationInfo'

type IndexIntegrationsSectionProps = {
  className?: string
  style?: CSSProperties
}

const IndexIntegrationsSection: FC<IndexIntegrationsSectionProps> = ({ className, style }) => {
  return (
    <>
      <div id="integration" className="anchor"></div>
      <div className={classNames(styles.component, className)} style={style}>
        <IntegrationInfo className={styles.integrationsInfo} />
        <Webshops className={styles.webshops} />
      </div>
    </>
  )
}

export default IndexIntegrationsSection
