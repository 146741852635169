import classNames from 'classnames'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import { CSSProperties, FC } from 'react'
import Avatar from '../Avatar/Avatar'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import OrangeRedBoxes from '../images/OrangeRedBoxes'
import styles from './CeoIntro.module.scss'

type Props = {
  className?: string
  style?: CSSProperties
}

const CeoIntro: FC<Props> = ({ className, style }) => {
  const t = useTranslations('index.introSection')

  return (
    <>
      <div className={classNames(styles.component, className)} style={style}>
        <div className={styles.boxes}>
          <OrangeRedBoxes />
        </div>
        <div className={styles.intro}>
          <div className={styles.introHeader}>
            <Avatar className={styles.avatar} imageSrc="/images/profile-photos/ceo.jpg" />
            <div className={styles.headerText}>
              <p className={styles.name}>{t('ceo-name')}</p>
              <p className={styles.ceoTitle}>{t('ceo-title')}</p>
              <p className={styles.ceoTitle}>{t('boxy-company-name')}</p>
            </div>
          </div>
          <p className={styles.ceoIntroText}>{t('ceo-intro')}</p>
        </div>
      </div>
      <div className={styles.interestedButtonContainer}>
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton label={t('interested-button-label')} />
          </a>
        </Link>
      </div>
    </>
  )
}

export default CeoIntro
