import classNames from 'classnames'
import { FC } from 'react'
import ArrowRightIcon from '../../icons/ArrowRightIcon'
import styles from './PrimaryButton.module.css'

type Props = {
  className?: string
  disabled?: boolean
  label: string
  onClick?: () => void
}

const PrimaryButton: FC<Props> = ({ className, disabled, label, onClick }) => {
  return (
    <button className={classNames(styles.button, className)} disabled={disabled} onClick={onClick}>
      {label}
      <div className={styles.arrow}>
        <ArrowRightIcon />
      </div>
    </button>
  )
}

export default PrimaryButton
