import { FC } from 'react'

type Props = {
  className?: string
}

const OrangeRedBoxes: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 824 836" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0)">
        <rect
          x="-555"
          y="178.408"
          width="1175"
          height="633.115"
          rx="80"
          transform="rotate(-5 -555 178.408)"
          fill="#D63615"
        />
        <path
          d="M-401.028 182.104C-404.878 138.089 -372.319 99.2865 -328.304 95.4357L682.833 6.97266C726.848 3.12188 765.65 35.6811 769.501 79.6958L811.634 561.275C815.485 605.29 782.925 644.093 738.911 647.943L-272.227 736.406C-316.241 740.257 -355.044 707.698 -358.895 663.683L-401.028 182.104Z"
          fill="#F4A900"
        />
        <mask
          id="mask0"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-402"
          y="6"
          width="1214"
          height="729"
        >
          <path
            d="M-401.028 182.104C-404.878 138.089 -372.319 99.2865 -328.304 95.4357L682.833 6.97266C726.848 3.12188 765.65 35.6811 769.501 79.6958L811.483 559.548C815.334 603.563 782.774 642.365 738.76 646.216L-272.378 734.679C-316.392 738.53 -355.195 705.971 -359.046 661.956L-401.028 182.104Z"
            fill="#F4A900"
          />
        </mask>
        <g mask="url(#mask0)">
          <circle
            opacity="0.5"
            cx="-58.5"
            cy="924.5"
            r="550.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="0.2 10"
          />
          <circle
            opacity="0.5"
            cx="-115.5"
            cy="981.5"
            r="493.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="0.2 10"
          />
          <circle
            opacity="0.5"
            cx="-191.5"
            cy="1050.5"
            r="448.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="0.2 10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1232" height="836" fill="white" transform="translate(-408)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OrangeRedBoxes
