import { CSSProperties, FC } from 'react'
import styles from './Avatar.module.css'

type Props = {
  className?: string
  imageSrc: string
  style?: CSSProperties
}

const Avatar: FC<Props> = ({ className, imageSrc, style }) => {
  return (
    <div className={className} style={style}>
      <img src={imageSrc} className={styles.image} alt="avatar" />
    </div>
  )
}

export default Avatar
