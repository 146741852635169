import classNames from 'classnames'
import { FC } from 'react'
import PackingIcon from '../icons/PackingIcon'
import SupportIcon from '../icons/SupportIcon'
import TransportIcon from '../icons/TransportIcon'
import WarehouseIcon from '../icons/WarehouseIcon'
import styles from './ImageBlock.module.scss'

type Props = {
  color: 'red' | 'orange' | 'blue' | 'green'
  icon: 'warehouse' | 'transport' | 'support' | 'packing'
  className?: string
  title: string
  texts: string[]
  cta: string
}

const colors = {
  red: styles.red,
  orange: styles.orange,
  blue: styles.blue,
  green: styles.green,
}

const icons = {
  warehouse: WarehouseIcon,
  transport: TransportIcon,
  support: SupportIcon,
  packing: PackingIcon,
}

const ImageBlock: FC<Props> = ({ color, icon, className, title, texts, cta }) => {
  const currentColor = colors[color] || colors.red
  const CurrentIcon = icons[icon] || icons.support

  return (
    <>
      <div className={classNames(styles.component, currentColor, className)}>
        <div className={styles.icon}>
          <CurrentIcon />
        </div>
        <div className={styles.title}>{title}</div>
        {texts.map((text) => (
          <div key={text} className={styles.text}>
            {text}
          </div>
        ))}
        <div className={styles.cta}>{cta}</div>
      </div>
    </>
  )
}

export default ImageBlock
