import classnames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import ThreeByThreeDots from '../ThreeByThreeDots/ThreeByThreeDots'
import styles from './TextInfoBlock.module.scss'

type Props = {
  className?: string
}

const TextInfoBlock: FC<Props> = ({ className }) => {
  const t = useTranslations('index.servicesSection')

  return (
    <div className={classnames(className, styles.component)}>
      <ThreeByThreeDots className={styles.dots} />
      <div className={styles.label}>{t('services-label')}</div>
      <h1 className={styles.title}>{t('services-title')}</h1>
      <div className={styles.content}>{t('services-content-1')}</div>
      <div className={styles.highlightedContent}>{t('services-content-2')}</div>
      <ul className={styles.list}>
        <li>
          <div>{t('services-content-list-1')}</div>
        </li>
        <li>
          <div>{t('services-content-list-2')}</div>
        </li>
        <li>
          <div>{t('services-content-list-3')}</div>
        </li>
        <li>
          <div>{t('services-content-list-4')}</div>
        </li>
      </ul>
      <Link href="/contact-us">
        <a>
          <SeparatedIconButton
            className={styles.interestedButton}
            label={t('interested-button-label')}
          />
        </a>
      </Link>
    </div>
  )
}

export default TextInfoBlock
