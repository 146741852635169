import classnames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import ImageBlock from './ImageBlock'
import styles from './ImageBlocks.module.scss'

type Props = {
  className?: string
}

const ImageBlocks: FC<Props> = ({ className }) => {
  const t = useTranslations('index.servicesSection')
  return (
    <div className={classnames(className, styles.component)}>
      <div className={styles.explodedBlocks}>
        <div>
          <RedBlock />
          <BlueBlock />
        </div>
        <div>
          <OrangeBlock />
          <GreenBlock />
        </div>
      </div>
      <div className={styles.normalBlocks}>
        <RedBlock />
        <OrangeBlock />
        <BlueBlock />
        <GreenBlock />
      </div>

      <Link href="/contact-us">
        <a>
          <SeparatedIconButton
            className={styles.interestedButton}
            label={t('interested-button-label')}
          />
        </a>
      </Link>
    </div>
  )
}

const RedBlock: FC = () => {
  const t = useTranslations('index.servicesSection.boxes')

  return (
    <ImageBlock
      className={classnames(styles.block, styles.red)}
      color="red"
      icon="warehouse"
      title={t('box-1-title')}
      texts={[t('box-1-text-1'), t('box-1-text-2'), t('box-1-text-3')]}
      cta={t('box-1-cta')}
    />
  )
}

const OrangeBlock: FC = () => {
  const t = useTranslations('index.servicesSection.boxes')

  return (
    <ImageBlock
      className={classnames(styles.block, styles.orange)}
      color="orange"
      icon="packing"
      title={t('box-2-title')}
      texts={[t('box-2-text-1'), t('box-2-text-2'), t('box-2-text-3')]}
      cta={t('box-2-cta')}
    />
  )
}

const BlueBlock: FC = () => {
  const t = useTranslations('index.servicesSection.boxes')

  return (
    <ImageBlock
      className={classnames(styles.block, styles.blue)}
      color="blue"
      icon="transport"
      title={t('box-3-title')}
      texts={[t('box-3-text-1'), t('box-3-text-2'), t('box-3-text-3')]}
      cta={t('box-3-cta')}
    />
  )
}

const GreenBlock: FC = () => {
  const t = useTranslations('index.servicesSection.boxes')

  return (
    <ImageBlock
      className={classnames(styles.block, styles.green)}
      color="green"
      icon="support"
      title={t('box-4-title')}
      texts={[t('box-4-text-1'), t('box-4-text-2')]}
      cta={t('box-4-cta')}
    />
  )
}

export default ImageBlocks
