import { useEffect, useState, RefObject } from 'react'

export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isOnScreen, setIsOnScreen] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting)
    })
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isOnScreen
}
