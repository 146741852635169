import classNames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import styles from './IndexCredoSection.module.scss'
import TextInfoBlock from './TextInfoBlock'
import ImageBlock from './ImageBlock'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'

type Props = {
  className?: string
}

const IndexCredoSection: FC<Props> = ({ className }) => {
  const t = useTranslations('index.credoSection')

  return (
    <>
      <div id="our-team" className="anchor"></div>
      <div className={classNames(className, styles.component)}>
        <TextInfoBlock />
        <ImageBlock className={styles.imageBlock} />
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton className={styles.interestedButton} label={t('cta-label-small')} />
          </a>
        </Link>
      </div>
    </>
  )
}

export default IndexCredoSection
