import { createRef } from 'react'

import Header from '../components/Header/Header'
import IndexIntroSection from '../components/IndexIntroSection/IndexIntroSection'
import IndexServicesSection from '../components/IndexServicesSection/IndexServicesSection'
import SzechenyiLogoWithLink from '../components/SzechenyiLogoWithLink/SzechenyiLogoWithLink'
import IndexFooterSection from '../components/IndexFooterSection/IndexFooterSection'
import IndexLogisticsSection from '../components/IndexLogisticsSection/IndexLogisticsSection'
import HeroImage from '../components/HeroImage/HeroImage'
import IndexCredoSection from '../components/IndexCredoSection/IndexCredoSection'
import IndexIntegrationsSection from '../components/IndexIntegrationsSection/IndexIntegrationsSection'
import Fab from '../components/Fab/Fab'
import useOnScreen from '../hooks/useOnScreen'

export default function IndexPage() {
  const headerRef = createRef<HTMLElement>()
  const isHeaderMenuVisible = useOnScreen(headerRef)

  return (
    <div className="page">
      <Header ref={headerRef} />
      <HeroImage />
      <div className="pageContent">
        {!isHeaderMenuVisible && <Fab />}
        <IndexLogisticsSection />
        <IndexIntroSection />
        <IndexServicesSection />
        <IndexIntegrationsSection />
        <IndexCredoSection />
        <IndexFooterSection />
      </div>
      <SzechenyiLogoWithLink />
    </div>
  )
}

export async function getStaticProps() {
  return {
    props: {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      messages: require(`../i18n/hu.json`),
    },
  }
}
