import classNames from 'classnames'
import { FC } from 'react'
import ImageBlock from './ImageBlock'
import TextInfoBlock from './TextInfoBlock'
import styles from './IndexLogisticsSection.module.scss'

type Props = {
  className?: string
}

const IndexLogisticsSection: FC<Props> = ({ className }) => {
  return (
    <div className={classNames(className, styles.component)}>
      <TextInfoBlock />
      <ImageBlock />
    </div>
  )
}

export default IndexLogisticsSection
