import useTranslations from '../I18nProvider/I18nProvider'
import { CSSProperties, FC } from 'react'
import Link from 'next/link'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import ThreeByThreeDots from '../ThreeByThreeDots/ThreeByThreeDots'
import styles from './BoxyIntro.module.scss'

type Props = {
  className?: string
  style?: CSSProperties
}

const BoxyIntro: FC<Props> = ({ className, style }) => {
  const t = useTranslations('index.introSection')

  return (
    <article className={className} style={style}>
      <div className={styles.dotsContainer}>
        <ThreeByThreeDots className={styles.dots} />
      </div>
      <div className={styles.label}>{t('introduction-label')}</div>
      <h1 className={styles.title}>{t('introduction-title')}</h1>
      <p className={styles.introduction}>{t('introduction')}</p>
      <div className={styles.interestedButtonContainer}>
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton label={t('interested-button-label')} />
          </a>
        </Link>
      </div>
    </article>
  )
}

export default BoxyIntro
