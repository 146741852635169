import classnames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import ThreeByThreeDots from '../ThreeByThreeDots/ThreeByThreeDots'
import styles from './TextInfoBlock.module.scss'

type Props = {
  className?: string
}

const TextInfoBlock: FC<Props> = ({ className }) => {
  const t = useTranslations('index.credoSection')

  return (
    <div className={classnames(className, styles.component)}>
      <ThreeByThreeDots className={styles.dots} />
      <div className={styles.label}>{t('label')}</div>
      <h1 className={styles.title}>{t('title')}</h1>
      <div className={styles.content}>{t('content-1')}</div>
      <div className={styles.highlightedContent}>{t('content-2')}</div>
      <Link href="/contact-us">
        <a>
          <SeparatedIconButton className={styles.interestedButton} label={t('cta-label')} />
        </a>
      </Link>
    </div>
  )
}

export default TextInfoBlock
