import { FC } from 'react'
import useTranslations from '../I18nProvider/I18nProvider'
import ImageSwapper from '../ImageSwapper/ImageSwapper'
import styles from './HeroImage.module.scss'

const images = ['/images/hero-robot.png', '/images/hero-girl.png', '/images/hero-conveyor.png']

const HeroImage: FC = () => {
  const t = useTranslations('index.logisticsSection')
  const imageAlts = [
    t('robot-image-description'),
    t('girl-image-description'),
    t('conveyor-image-description'),
  ]
  return (
    <div className={styles.component}>
      <ImageSwapper
        images={images}
        imageAlts={imageAlts}
        className={styles.heroImage}
        delayBetweenImagesInMs={5000}
      />
    </div>
  )
}

export default HeroImage
