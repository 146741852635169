import classNames from 'classnames'
import { FC } from 'react'
import useTranslations from '../I18nProvider/I18nProvider'
import ImageSwapper from '../ImageSwapper/ImageSwapper'
import styles from './ImageBlock.module.scss'

type Props = {
  className?: string
}

const images = ['/images/hero-robot.png', '/images/hero-girl.png', '/images/hero-conveyor.png']

const TextInfoBlock: FC<Props> = ({ className }) => {
  const t = useTranslations('index.logisticsSection')
  const imageAlts = [
    t('robot-image-description'),
    t('girl-image-description'),
    t('conveyor-image-description'),
  ]

  return (
    <div className={classNames(className, styles.component)}>
      <ImageSwapper
        images={images}
        imageAlts={imageAlts}
        className={styles.image}
        delayBetweenImagesInMs={5000}
      />
    </div>
  )
}

export default TextInfoBlock
