import { FC } from 'react'

interface Props {
  className?: string
}

const WarehouseIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        opacity="0.7"
        d="M16 3L3 8.75389L3 29H5.16667L5.16667 10.1576L16 5.36057L26.8333 10.1576L26.8333 29H29L29 8.75389L16 3ZM11.6667 13.8923V14.9715V20.3671H8.41667L8.41667 21.4462V29H14.9167H17.0833L23.5833 29V20.3671H20.3333L20.3333 13.8923H11.6667ZM13.8333 16.0506H18.1667V20.3671L17.0833 20.3671H14.9167H13.8333V16.0506ZM10.5833 22.5253H11.6667H14.9167L14.9167 26.8418H10.5833V22.5253ZM17.0833 22.5253L20.3333 22.5253H21.4167V26.8418L17.0833 26.8418L17.0833 22.5253Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default WarehouseIcon
