import { FC } from 'react'

type Props = {
  className?: string
}

const PlanetaryCircles: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 971 833" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        opacity="0.8"
        d="M817 452C817 661.868 646.868 832 437 832C227.132 832 57 661.868 57 452C57 242.132 227.132 72 437 72C646.868 72 817 242.132 817 452Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.2 8"
      />
      <path
        opacity="0.8"
        d="M410 205.5C410 318.442 318.442 410 205.5 410C92.5578 410 1 318.442 1 205.5C1 92.5578 92.5578 1 205.5 1C318.442 1 410 92.5578 410 205.5Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.2 8"
      />
      <path
        opacity="0.8"
        d="M674 410.5C674 523.442 582.442 615 469.5 615C356.558 615 265 523.442 265 410.5C265 297.558 356.558 206 469.5 206C582.442 206 674 297.558 674 410.5Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.2 8"
      />
      <path
        opacity="0.8"
        d="M413 552.5C413 665.442 321.442 757 208.5 757C95.5578 757 4 665.442 4 552.5C4 439.558 95.5578 348 208.5 348C321.442 348 413 439.558 413 552.5Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.2 8"
      />
      <path
        opacity="0.8"
        d="M970 314.5C970 427.442 878.442 519 765.5 519C652.558 519 561 427.442 561 314.5C561 201.558 652.558 110 765.5 110C878.442 110 970 201.558 970 314.5Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.2 8"
      />
    </svg>
  )
}

export default PlanetaryCircles
