import classNames from 'classnames'
import { FC } from 'react'
import Avatar from '../Avatar/Avatar'
import styles from './AvatarWithHover.module.scss'

type Props = {
  className?: string
  imageSrc: string
  name: string
  title: string
  memberId: string
  highlightedMember: string | null
  onClick: () => void
}

const AvatarWithHover: FC<Props> = ({
  className,
  imageSrc,
  name,
  title,
  memberId,
  highlightedMember,
  onClick,
}) => {
  const isHighlighted = highlightedMember === memberId
  const isSomeoneElseHighlighted = !isHighlighted && highlightedMember !== null

  return (
    <div
      className={classNames(
        className,
        styles.component,
        isHighlighted && styles.show,
        isSomeoneElseHighlighted && styles.noHover,
      )}
      onClick={onClick}
    >
      <Avatar imageSrc={imageSrc} className={styles.avatar} />
      <div className={styles.overlay}>
        <div className={styles.name}>{name}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  )
}

export default AvatarWithHover
