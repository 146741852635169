import classNames from 'classnames'
import useTranslations from '../I18nProvider/I18nProvider'
import { CSSProperties, FC } from 'react'
import BoxyIntro from './BoxyIntro'
import CeoIntro from './CeoIntro'
import styles from './IndexIntroSection.module.scss'

type Props = {
  className?: string
  style?: CSSProperties
}

const IndexIntroSection: FC<Props> = ({ className, style }) => {
  const t = useTranslations('index.introSection')

  return (
    <>
      <div id="introduction" className="anchor"></div>
      <div className={classNames(styles.component, className)} style={style}>
        <BoxyIntro className={styles.boxyIntro} />
        <CeoIntro className={styles.ceoIntro} />
      </div>
    </>
  )
}

export default IndexIntroSection
