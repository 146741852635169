import classNames from 'classnames'
import { FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import PrimaryButton from '../buttons/PrimaryButton/PrimaryButton'
import styles from './TextInfoBlock.module.scss'

type Props = {
  className?: string
}

const TextInfoBlock: FC<Props> = ({ className }) => {
  const t = useTranslations('index.logisticsSection')

  return (
    <div className={classNames(className, styles.component)}>
      <h1 className={styles.title}>{t('title-1')}</h1>
      <h1 className={styles.title}>{t('title-2')}</h1>
      <h1 className={styles.title}>{t('title-3')}</h1>
      <div className={styles.content}>{t('content-1')}</div>
      <div className={styles.content}>{t('content-2')}</div>
      <div className={styles.content}>{t('content-3')}</div>
      <div className={styles.buttonContainer}>
        <Link href="/contact-us">
          <a>
            <PrimaryButton className={styles.interestedButton} label={t('cta-label')} />
          </a>
        </Link>
      </div>
      <div className={styles.note}>{t('note-1')}</div>
      <div className={styles.note}>{t('note-2')}</div>
    </div>
  )
}

export default TextInfoBlock
