import { FC } from 'react'

type Props = {
  className?: string
}

const GreenBlueBoxes: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 1075 1162" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-371.383" y="394.482" width="1175" height="700" rx="80" transform="rotate(-5 -371.383 394.482)" fill="#48B1E9"/>
      <rect x="-517" y="168.529" width="1428.82" height="852.715" rx="80" transform="rotate(-5 -517 168.529)" fill="#B4C822"/>
    </svg>
  )
}

export default GreenBlueBoxes

