import { FC, useState, useEffect } from 'react'

type Props = {
  images: string[]
  imageAlts?: string[]
  delayBetweenImagesInMs: number
  className?: string
}

const ImageSwapper: FC<Props> = ({ images, imageAlts = [], delayBetweenImagesInMs, className }) => {
  const [imageIndex, setImageIndex] = useState(0)

  useEffect(() => {
    const handler = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length)
    }, delayBetweenImagesInMs)

    return () => clearInterval(handler)
  })

  return <img src={images[imageIndex]} className={className} alt={imageAlts[imageIndex]} />
}

export default ImageSwapper
