import classNames from 'classnames'
import { FC, useState } from 'react'
import useTranslations from '../I18nProvider/I18nProvider'
import AvatarWithHover from './AvatarWithHover'
import PlanetaryCircles from '../images/PlanetaryCircles'
import styles from './ImageBlock.module.scss'

type Props = {
  className?: string
}

const TextInfoBlock: FC<Props> = ({ className }) => {
  const t = useTranslations('index.credoSection')
  const [highlightedMember, setHighlightedMember] = useState<string | null>(null)

  const clickHandler = (memberId: string) => {
    if (highlightedMember === memberId) {
      setHighlightedMember(null)
      return
    }

    setHighlightedMember(memberId)
  }

  return (
    <div className={classNames(className, styles.component)}>
      <PlanetaryCircles className={styles.background} />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.gereKaroly)}
        imageSrc="images/profile-photos/gere károly cropped.jpg"
        name={t('team.gere-karoly-name')}
        title={t('team.gere-karoly-title')}
        memberId={'gereKaroly'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('gereKaroly')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.guothPatrik)}
        imageSrc="images/profile-photos/guóth patrik cropped.jpg"
        name={t('team.guoth-patrik-name')}
        title={t('team.guoth-patrik-title')}
        memberId={'guothPatrik'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('guothPatrik')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.kamuthyLaszlo)}
        imageSrc="images/profile-photos/kamuthy lászló cropped.jpg"
        name={t('team.kamuthy-laszlo-name')}
        title={t('team.kamuthy-laszlo-title')}
        memberId={'kamuthyLaszlo'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('kamuthyLaszlo')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.kolbertIstvan)}
        imageSrc="images/profile-photos/kolbert istván cropped.jpg"
        name={t('team.kolbert-istvan-name')}
        title={t('team.kolbert-istvan-title')}
        memberId={'kolbertIstvan'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('kolbertIstvan')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.schmidtDaniel)}
        imageSrc="images/profile-photos/schmidt dániel cropped.jpg"
        name={t('team.schmidt-daniel-name')}
        title={t('team.schmidt-daniel-title')}
        memberId={'schmidtDaniel'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('schmidtDaniel')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.tothJozsef)}
        imageSrc="images/profile-photos/tóth józsef cropped.jpg"
        name={t('team.toth-jozsef-name')}
        title={t('team.toth-jozsef-title')}
        memberId={'tothJozsef'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('tothJozsef')}
      />
      <AvatarWithHover
        className={classNames(styles.avatar, styles.tancsicsAndras)}
        imageSrc="images/profile-photos/táncsics andrás cropped.jpg"
        name={t('team.tancsics-andras-name')}
        title={t('team.tancsics-andras-title')}
        memberId={'tancsicsAndras'}
        highlightedMember={highlightedMember}
        onClick={() => clickHandler('tancsicsAndras')}
      />
    </div>
  )
}

export default TextInfoBlock
