import { CSSProperties, FC, useState } from 'react'
import classNames from 'classnames'
import useTranslations from '../I18nProvider/I18nProvider'

import styles from './IntegrationBulletpoints.module.scss'
import Bulletpoint from './Bulletpoint'

type IntegrationBulletpointsProps = {
  className?: string
  style?: CSSProperties
}

const IntegrationBulletpoints: FC<IntegrationBulletpointsProps> = ({ className, style }) => {
  const t = useTranslations('index.integrationsSection')
  const [openedTitle, setOpenedTitle] = useState(t('how-to-join-title'))

  const openHandler = (title: string) => {
    setOpenedTitle(title)
  }

  return (
    <div className={classNames(styles.component, className)} style={style}>
      <ul className={styles.dottedLine}>
        <li>
          <Bulletpoint
            title={t('how-to-join-title')}
            content={[
              t('how-to-join-content-1'),
              t('how-to-join-content-2'),
              t('how-to-join-content-3'),
            ]}
            isOpen={t('how-to-join-title') === openedTitle}
            openHandler={openHandler}
          />
        </li>
        <li>
          <Bulletpoint
            title={t('how-to-integrate-title')}
            content={[
              t('how-to-integrate-content-1'),
              t('how-to-integrate-content-2'),
              t('how-to-integrate-content-3'),
            ]}
            isOpen={t('how-to-integrate-title') === openedTitle}
            openHandler={openHandler}
          />
        </li>
        <li>
          <Bulletpoint
            title={t('for-who-title')}
            content={[t('for-who-content-1'), t('for-who-content-2')]}
            isOpen={t('for-who-title') === openedTitle}
            openHandler={openHandler}
          />
        </li>
      </ul>
    </div>
  )
}

export default IntegrationBulletpoints
