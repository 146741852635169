import { CSSProperties, FC } from 'react'
import Link from 'next/link'
import useTranslations from '../I18nProvider/I18nProvider'
import classNames from 'classnames'

import SeparatedIconButton from '../buttons/SeparatedIconButton/SeparatedIconButton'
import GreenBlueBoxes from '../images/GreenBlueBoxes'
import styles from './Webshops.module.scss'

type WebshopsProps = {
  className?: string
  style?: CSSProperties
}

const Webshops: FC<WebshopsProps> = ({ className, style }) => {
  const t = useTranslations('index.integrationsSection')

  return (
    <>
      <div className={classNames(styles.component, className)} style={style}>
        <div className={styles.boxes}>
          <GreenBlueBoxes />
        </div>
        <div className={styles.webshops}>
          <img src="/images/webshops.png" alt="webshops" />
        </div>
      </div>
      <div className={styles.furtherInfoButtonContainer}>
        <Link href="/contact-us">
          <a>
            <SeparatedIconButton label={t('further-info-button-label')} />
          </a>
        </Link>
      </div>
    </>
  )
}

export default Webshops
