import { FC } from 'react'

interface Props {
  className?: string
}

const ArrowUpIcon: FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M10.5 26C10.5 26.8284 11.1716 27.5 12 27.5C12.8284 27.5 13.5 26.8284 13.5 26L10.5 26ZM13.0607 0.939341C12.4749 0.353554 11.5251 0.353554 10.9393 0.939341L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97918 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939341ZM13.5 26L13.5 2L10.5 2L10.5 26L13.5 26Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowUpIcon
